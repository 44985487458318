import { useState, useEffect } from "react";
import { Navigation } from "../components/navigation";
import { Header } from "../components/header";
import { Contact } from "../components/contact";
import { SpinoffServices } from "../components/spinoffServices";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import "../App.css";
import 'antd/dist/antd.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Spinoff = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    window.scrollTo(0,0)
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation data={landingPageData.Navigation} Spinoff={true} />
      <Header data={landingPageData.Header} Spinoff={true}/>
      <SpinoffServices data={landingPageData.Spinoff}/>
      <Contact data={landingPageData.Contact} Spinoff={true} />
    </div>
  );
};

export default Spinoff;
