import Slider from "react-slick";

export const Features = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1
  };
  return (
    <div id='features' className='text-center'>
      <div className='customers'>
        <h2></h2>
      </div>
      <div className='container'>
        <div className='col-md-4 col-sm-12 customer_flex'>
          <div className='customer'>
            {props.data &&
              <img src={props.data.img7} style={{width: "45%"}}/>
            }
          </div>
        </div>
        <div className='col-md-4 col-sm-12 customer_flex'>
          <div className='customer'>
            {props.data &&
              <img src={props.data.img2} style={{width: "45%"}}/>
            }
          </div>
        </div>
        <div className='col-md-4 col-sm-12 customer_flex'>
          <div className='customer'>
            {props.data &&
              <img src={props.data.img6} style={{width: "25%"}}/>
            }
          </div>
        </div>
        </div>
    </div>
  )
}
