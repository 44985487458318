import { Anchor, Popover } from 'antd';
import React, { useState, useEffect } from 'react';

export const SideDots = (props) => {
    const { Link } = Anchor;
    const [targetOffset, setTargetOffset] = useState(undefined);
    useEffect(() => {
        setTargetOffset(window.innerHeight / 2);
    }, []);
    return (
        <Anchor className='sideDots' targetOffset={targetOffset}>
            <Link href="#features" title={
                <Popover placement="left" content={<span>Our Partners</span>}>
                    <div className='dots'></div>
                </Popover>
            } />
            <Link href="#products" title={
                <Popover placement="left" content={<span>Supported Devices</span>}>
                    <div className='dots'></div>
                </Popover>
            } />
            <Link href="#services" title={
                <Popover placement="left" content={<span>Our Services</span>}>
                    <div className='dots'></div>
                </Popover>
            }  />
            <Link href="#advantage" title={
                <Popover placement="left" content={<span>Why MioConnect</span>}>
                    <div className='dots'></div>
                </Popover>
            } />
            <Link href="#examples" title={
                <Popover placement="left" content={<span>Use Cases</span>}>
                    <div className='dots'></div>
                </Popover>
            }  />
            <Link href="#contact" title={
                <Popover placement="left" content={<span>Free Trial</span>}>
                    <div className='dots'></div>
                </Popover>
            }  />
        </Anchor>
    );
}