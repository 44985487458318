import { useState, useEffect } from "react";
import { Navigation } from "../components/navigation";
import { Header } from "../components/header";
import { Features } from "../components/features";
import { Products } from "../components/products";
import { Advantages } from "../components/advantage";
import { Examples } from "../components/examples";
import { Contact } from "../components/contact";
import { SideDots } from "../components/sideDots";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import "../App.css";
import 'antd/dist/antd.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    window.scrollTo(0,0)
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation data={landingPageData.Navigation} />
      <SideDots/>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <Products data={landingPageData.About} />
      {/* <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery}/>
      <Testimonials data={landingPageData.Testimonials} /> */}
      <Advantages data={landingPageData.Advantages} />
      <Examples data={landingPageData.Featured} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
