export const SpinoffServices = (props) => {
  return (
    <div className="spinoff">
      <div className="service">
      <div className="container">
        <br/>
        <div className="row flex">
          <div className="col-sm-12 col-xs-12 col-md-6 center">
            <div className="about-text">
              <h2>Research and development of new technologies and products</h2>
              <span className='description'>Sphygmomanometers, oximeters, blood glucose meters, thermometers, smart watches, exercise monitoring equipment, body scales, etc.
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-xs-12 col-md-6 center">
            {props.data &&
              <img src={props.data.service1} style={{width: "90%"}}/>
            }
          </div>
        </div>
      </div>
      </div>
      
      <div className="service" style={{background: "#F6F8FC"}}>
      <div className="container">
        <br/>

        <div className="row flex">
          <div className="col-sm-12 col-xs-12 col-md-6 center-img">
            {props.data &&
              <img src={props.data.service2} style={{width: "70%"}}/>
            }
          </div>
          <div className="col-sm-12 col-xs-12 col-md-6 center">
            <div className="about-text">
              <h2>Provide APP product design and development services</h2>
              <span className='description'>Facing the field of medical health and sports, it has cloud computing and cloud storage functions, complete functions, strong security, ease of use, good flexibility, and good scalability，When accessing the database, effectively check the access status of the cloud database. It can be integrated with medical health monitoring equipment and sports monitoring equipment, and can be customized according to user needs. Create a personalized display page, display data analysis reports and health exercise suggestions, and provide comprehensive update and maintenance services
              </span>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="service">
      <div className="container">
        <br/>

        <div className="row flex">
          <div className="col-sm-12 col-xs-12 col-md-6 center">
            <div className="about-text">
              <h2>Provide retail and wholesale services</h2>
              <span className='description'>Retail and wholesale of blood pressure monitors, heart rate monitoring devices, earphones, hearing aids, smart watches, watches, watch gift boxes,scales
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-xs-12 col-md-6 center-img">
            {props.data &&
              <img src={props.data.service3} style={{width: "70%"}}/>
            }
          </div>
        </div>
      </div>
      </div>

      <div className="service" style ={{background: "#F6F8FC"}}>
      <div className="container">
        <br/>

        <div className="row flex">
          <div className="col-sm-12 col-xs-12 col-md-6 center-img">
            {props.data &&
              <img src={props.data.service4} style={{width: "90%"}}/>
            }
          </div>
          <div className="col-sm-12 col-xs-12 col-md-6 center">
            <div className="about-text">
              <h2>telemedicine service</h2>
              <span className='description'>Provide medical services to chronically ill people through communication and computer technology, form a telemedicine network for intelligent products such as blood pressure, blood sugar, heart rate, and blood oxygen, and connect medical institutions and insurance institutions for continuous, effective, and timely intelligent information detection and medical feedback .
              </span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}
