import { Card } from 'antd';

const { Meta } = Card;

export const Examples = (props) => {
  return (
    <div id='examples' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2></h2>
        </div>
        <div className='row'>
          <div className='col-md-6 col-sm-12 team'>
            <div className='thumbnail'>
              {props.data &&
                  <Card
                      bordered={false}
                      cover={<img alt="example" src={props.data.img1} style={{width: "100%"}} />}
                  >
                      <Meta title="An RPM service provider accessed 4G blood pressure monitor for chronic disease management" 
                      description={
                        <div>
                          <p><i className="iconfont icon-a-lujing801"/>Developed 4G Blood Pressure Monitors</p>
                          <p><i className="iconfont icon-a-lujing801"/>Delivered global 4G roaming cellular networkProvided</p>
                          <p><i className="iconfont icon-a-lujing801"/>Device and Connection Management Platform</p>
                        </div>
                      } />
                  </Card>
              }
            </div>
          </div>

          <div className='col-md-6 col-sm-12 team'>
            <div className='thumbnail'>
              {props.data &&
                  <Card
                      bordered={false}
                      cover={<img alt="example" src={props.data.img2} style={{width: "100%"}} />}
                  >
                      <Meta title="A clinic connected to 4G medical Devices for chronic disease management" 
                      description={
                        <div>
                          <p><i className="iconfont icon-a-lujing801"/>Distributed 4G Blood Pressure Monitors and Scales</p>
                          <p><i className="iconfont icon-a-lujing801"/>Delivered global 4G roaming cellular network</p>
                          <p><i className="iconfont icon-a-lujing801"/>Provided Device and Connection Management Platform</p>
                        </div>
                      }  />
                  </Card>
              }
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-sm-12 team'>
            <div className='thumbnail'>
              {props.data &&
                  <Card
                      bordered={false}
                      cover={<img alt="example" src={props.data.img3} style={{width: "100%"}} />}
                  >
                      <Meta title="A company provided access to WiFi medical equipment to medical institutions" 
                      description={
                        <div>
                          <p><i className="iconfont icon-a-lujing801"/>Provided WiFi-connected scales</p>
                          <p><i className="iconfont icon-a-lujing801"/>Provided Device management platform</p>
                        </div>
                      }  />
                  </Card>
              }
            </div>
          </div>

          <div className='col-md-6 col-sm-12 team'>
            <div className='thumbnail'>
              {props.data &&
                  <Card
                      bordered={false}
                      cover={<img alt="example" src={props.data.img4} style={{width: "100%"}} />}
                  >
                      <Meta title="A public hospital connected to a Bluetooth device for medical research" 
                      description={
                        <div>
                          <p><i className="iconfont icon-a-lujing801"/>Bluetooth bracelet</p>
                          <p><i className="iconfont icon-a-lujing801"/>Mobile sdk</p>
                          <p><i className="iconfont icon-a-lujing801"/>Device management platform</p>
                        </div>
                      }  />
                  </Card>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
