import { useState } from 'react';
import { Link } from "react-router-dom";

export const Navigation = (props) => {
  const [showTabs, setShowTabs] = useState(false)

  const handleShowTabs = () => {
    setShowTabs(!showTabs);
  }

  const Spinoff = props.Spinoff ? true : false

  return (
    <div className={Spinoff ? 'spinoff' : ''}>
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {
            Spinoff ? <img src='./img/navigation/spinoffNavigation.png' width='24px' /> : <img src='./img/navigation/navigation.png' width='24px' />
            }
            {/* {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '} */}
          </button>
          {props.data &&
            <>
              <a href="#header">
                { Spinoff && 
                  <img src={props.data.spinoff} style={{width: "180px", padding: "0px 2px"}}/>
                }
                { !Spinoff && 
                  <img src={props.data.img} style={{width: "180px", padding: "0px 2px"}}/>
                }
              </a>
            </>
          }
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
          { !Spinoff && 
            <li>
              <a className='page-scroll moblie-show' onClick={handleShowTabs}>
                Home
              </a>
            </li>
          }
          { Spinoff && 
            <li>
              <Link to='/' className='page-scroll'>
                Home
              </Link>
            </li>
          }
            {showTabs && 
              <>
                <li>
                  <a href="#features" className='page-scroll moblie-show insideTab'>
                    Our Partners
                  </a>
                </li>
                <li>
                  <a href="#products" className='page-scroll moblie-show insideTab'>
                    Supported Devices
                  </a>
                </li>
                <li>
                  <a href="#services" className='page-scroll moblie-show insideTab'>
                    Our Services
                  </a>
                </li>
                <li>
                  <a href="#advantage" className='page-scroll moblie-show insideTab'>
                    Why MioConnect
                  </a>
                </li>
                <li>
                  <a href="#examples" className='page-scroll moblie-show insideTab'>
                    Use Cases
                  </a>
                </li>
              </>
            }
            <li>
              <a href='https://developer.mio-labs.com' target="_blank" className='page-scroll'>
                API DOCUMENT
              </a>
            </li>
            <li>
              <a href='https://connect.mio-labs.com' target="_blank" className='page-scroll'>
                DASHBOARD
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                APPLY FOR TRIAL
              </a>
            </li>
            { !Spinoff && 
              <li>
                <Link to='/about' className='page-scroll'>
                  Spinoff brand
                </Link>
              </li>
            }
          </ul>
        </div>
      </div>
    </nav>
    </div>
  )
}