import { Card } from 'antd';

const { Meta } = Card;

export const Advantages = (props) => {
return (
    <div id='advantage' className='text-center'>
    <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
        <h2></h2>
        </div>
        <div id='row'>
            <div className='col-md-4 col-sm-6 team'>
                <div className='thunbnail'>
                {props.data &&
                    <Card
                        bordered={false}
                        cover={<img alt="example" src={props.data.img1} style={{width: "30%"}} />}
                    >
                        <Meta title="Customized Solution" description="We provide customized connectivity solutions to improve your service outcome" />
                    </Card>
                }
                </div>
            </div>
            <div className='col-md-4 col-sm-6 team'>
                <div className='thunbnail'>
                {props.data &&
                    <Card
                        bordered={false}
                        cover={<img alt="example" src={props.data.img2} style={{width: "30%"}} />}
                    >
                        <Meta title="One-Stop Service" description="Our one-stop service includs devices, APPs,  Sim cards, installation, logistics, device＆data management etc." />
                    </Card>
                }
                </div>
            </div>
            <div className='col-md-4 col-sm-6 team'>
                <div className='thunbnail'>
                {props.data &&
                    <Card
                        bordered={false}
                        cover={<img alt="example" src={props.data.img3} style={{width: "30%"}} />}
                    >
                        <Meta title="Stable, Reliable, and Secure Network" description="We implement advanced technologies to ensure cyber security." />
                    </Card>
                }
                </div>
            </div>
        </div>
    </div>
    </div>
)
}
