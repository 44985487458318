import { useState } from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";

import App from './views/App';
import Spinoff from './views/Spinoff';

export const Router = (props) => {
    return(
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="about" element={<Spinoff />} />
        </Routes>
    )
}