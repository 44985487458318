import Slider from "react-slick";
import React, { useState, useEffect } from 'react';
import { useCallback } from "react";

let settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1
}
export const Products = (props) => {
  const [slickSettings, setSlickSettings] = useState(settings)

  useEffect(() => {
    let nextImg = '';
    let prevImg = '';
    let arrowNext = document.getElementsByClassName('slick-next');
    let prevNext = document.getElementsByClassName('slick-prev');
    arrowNext[0].innerHTML = '';
    prevNext[0].innerHTML = '';
    let newNextArrow = document.createElement('img');
    let newPrevArrow = document.createElement('img');
    if(props.data){
      nextImg = props.data.arrow;
      prevImg = props.data.arrow;
      newNextArrow.src = nextImg;
      newPrevArrow.src = prevImg;
      arrowNext[0].appendChild(newNextArrow);
      prevNext[0].appendChild(newPrevArrow);
    }
  }, [props.data])

  const handleResize = useCallback(() => {
    if( window.innerWidth < 991){
      setSlickSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      })
    }else{
      setSlickSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
      })
    }
  }, [])

  useEffect(()=>{
    if( window.innerWidth < 991){
      setSlickSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      })
    }else{
      setSlickSettings({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
      })
    }
    window.addEventListener('resize', handleResize) 
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerWidth])

  return (
    <div id="products">
      <div className="container">
        <div className='row'>
          <div className='col-md-12 section-title'>
            <h2>All devices are supported</h2>
            <div className='description'>
              <span>Devices are the essential infrastructure of Telehealth. Regardless of the limitation of choice while you shop around, we can help purchase and design all devices you need for your business. Types of equipment we can assist include but are not limited to FDA-approved products, connected healthcare devices, wearables, and outputs devices. In addition, we can support you to turn your own ideas into reality.</span>
            </div>
            <div>
              <Slider {...slickSettings}>
                <div className="product">
                  {props.data &&
                    <div className='col-md-12 col-sm-12'>
                      <img src={props.data.product10} style={{width: "65%"}}/>
                    </div>
                  }
                  <div className='product_title'>
                    <span>Customized device option available </span>
                  </div>
                </div>
                <div className="product">
                  {props.data &&
                    <div className='col-md-12 col-sm-12'>
                      <img src={props.data.product4} style={{width: "75%"}}/>
                    </div>
                  }
                  <div className='product_title'>
                    <span>Blood Pressure Monitors</span>
                  </div>
                </div>
                <div className="product">
                  {props.data &&
                    <div className='col-md-12 col-sm-12'>
                      <img src={props.data.product3} style={{width: "55%"}}/>
                    </div>
                  }
                  <div className='product_title'>
                    <span>Weight Scales</span>
                  </div>
                </div>
                <div className="product">
                  {props.data &&
                    <div className='col-md-12 col-sm-12'>
                      <img src={props.data.product2} style={{width: "50%"}}/>
                    </div>
                  }
                  <div className='product_title'>
                    <span>Blood Glucose Meters</span>
                  </div>
                </div>
                <div className="product">
                  {props.data &&
                    <div className='col-md-12 col-sm-12'>
                      <img src={props.data.product1} style={{width: "60%"}}/>
                    </div>
                  }
                  <div className='product_title'>
                    <span>Pulse Oximeters</span>
                  </div>
                </div>
                <div className="product">
                  {props.data &&
                    <div className='col-md-12 col-sm-12'>
                      <img src={props.data.product5} style={{width: "60%"}}/>
                    </div>
                  }
                  <div className='product_title'>
                    <span>ECG Watches</span>
                  </div>
                </div>
                <div className="product">
                  {props.data &&
                    <div className='col-md-12 col-sm-12'>
                      <img src={props.data.product6} style={{width: "45%"}}/>
                    </div>
                  }
                  <div className='product_title'>
                    <span>Thermometers</span>
                  </div>
                </div>
                <div className="product">
                  {props.data &&
                    <div className='col-md-12 col-sm-12'>
                      <img src={props.data.product7} style={{width: "50%"}}/>
                    </div>
                  }
                  <div className='product_title'>
                    <span>Cameras</span>
                  </div>
                </div>
                <div className="product">
                  {props.data &&
                    <div className='col-md-12 col-sm-12'>
                      <img src={props.data.product8} style={{width: "50%"}}/>
                    </div>
                  }
                  <div className='product_title'>
                    <span>Wearables</span>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="container">
        <div className="row flex">
          <div className="col-md-5 col-sm-12 col-xs-12 center-img">
            {props.data &&
              <img src={props.data.slice1} style={{width: "90%"}}/>
            }
          </div>
          <div className="col-md-7 col-sm-12 col-xs-12 center">
            <h2>All types of connections are supported!</h2>
            <span className='description'> It is a struggle to decide which connect method you can use on your devices. 
              However, at MioConnect, we support all types of connections as you requested. 
            </span>
            <h4><i className="iconfont icon-a-lujing801"/>Bluetooth Connection</h4>
            <span className='text'>&bull;&nbsp;Our AnyHub works with Bluetooth devices (peripheral) in any protocol definition. 
              It can help anyone with any device in any place to participate in telehealth. 
              No OTA, WiFi, or App download is needed to use.
            </span>
            <h4><i className="iconfont icon-a-lujing801"/>4G Connection</h4>
            <span className='text'>&bull;&nbsp;Our global 4G roaming cellular network supports telehealth devices with an easy-to-use function 
              for both health providers and patients. No WiFi, Bluetooth, tablet, smartphone, 
              or App installation is needed for your clients. 
            </span>
            <br/>
            <span className='text'>&bull;&nbsp;Provide database access services and effectively check the access status of cloud databases. </span>
            <br/>
            <span className='text'>&bull;&nbsp;We provide Flexible and Unlimited Plans for your connectivity. </span>
            <h4><i className="iconfont icon-a-lujing801"/>Wifi Connection</h4>
            <h4><i className="iconfont icon-a-lujing801"/>Your Connection</h4>
            <span className='description contact'>Contact us to support your own connection</span>
          </div>
        </div>

        <br/>

        <div className="row flex">
          <div className="col-sm-12 col-xs-12 col-md-6 center">
            <div className="about-text">
              <h2>Product development and sales Services</h2>
              <span className='description'>Whether you are looking for a specific device that exists on the market or not, 
                we are here to help! Contact us if you need: 
              </span>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>Customized device service, including research and development of new products and new technologies;</h4></div>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>Customized APP services, including APP research and development;</h4></div>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>One-stop procurement service, including product retail and wholesale;</h4></div>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>Covering blood pressure monitors, heart rate monitoring equipment, earphones, hearing aids, smart watches, watches and other new products and APPs for them.</h4></div>
            </div>
          </div>
          <div className="col-sm-12 col-xs-12 col-md-6 center-img">
            {props.data &&
              <img src={props.data.slice2} style={{width: "100%"}}/>
            }
          </div>
        </div>

        <br/>

        <div className="row flex">
          <div className="col-sm-12 col-xs-12 col-md-6 center-img">
            {props.data &&
              <img src={props.data.slice3} style={{width: "90%"}}/>
            }
          </div>
          <div className="col-sm-12 col-xs-12 col-md-6 center">
            <div className="about-text">
              <h2>Connectivity Services</h2>
              <span className='description'>If you are looking for connectivity solutions for your devices, 
              MioConnect is here! Contact us today for： 
              </span>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>Customized connectivity service</h4></div>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>Design a connectivity solution for your devices</h4></div>
            </div>
          </div>
        </div>

        <br/>

        <div className="row flex">
          <div className="col-sm-12 col-xs-12 col-md-6 center">
            <div className="about-text">
              <h2>Connection and Device Management Service</h2>
              <span className='description'>Our IoT platform provides remote device monitoring and management services. 
              In addition, it technically supports end-to-end data encryption, flexible data transmission options and OTA (over-the-air) configuration, so the service performance is more stable.Through our platform, you can:
              </span>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>Check on connection status，including access status of cloud database</h4></div>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>Check on device status</h4></div>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>Check on device's data usage</h4></div>
              <div className="grey"><i className="iconfont icon-a-lujing801"/><h4>telemedicine service<br/>
In particular, telemedicine services provide medical services to chronically ill people through communication and computer technology, and form a telemedicine network for smart medical equipment such as blood pressure, blood sugar, heart rate, and blood oxygen, and interact with the test results with the medical structure to facilitate doctors' testing and guidance , for continuous, effective and timely intelligent information detection and medical feedback.</h4></div>
            </div>
          </div>
          <div className="col-sm-12 col-xs-12 col-md-6 center-img">
            {props.data &&
              <img src={props.data.slice4} style={{width: "90%"}}/>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
