import { Carousel, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

export const Header = (props) => {
  const scrollToContact = () => {
    let contact = document.getElementById('contact');
    contact.scrollIntoView({block: 'start', behavior: 'smooth'});
  }
  const Spinoff = props.Spinoff ? true : false
  return (
    <header id='header'>
      {!Spinoff &&   
      <Carousel dots={true} autoplay>
          <div className='intro'>
            <div className='overlay'>
                <div className='container'>
                <div className='row' style={{marginTop: "150px"}}>
                    <div className='col-md-6'>
                      <h1>
                        Kickstart your Telehealth practice by connecting ALL devices you need 
                      </h1>
                      <div className='header_description'>
                        <span></span>
                      </div>
                      <div>
                      <Button onClick={scrollToContact} className='header-btn'>Try MioConnect for Free<div className='button-icon'><i className='iconfont icon-a-jiantou1'/></div></Button>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      {props.data &&
                        <img className='header-img-2' src={props.data.img2} style={{width: "100%"}}/>
                      }
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className='intro'>
            <div className='overlay'>
              <div className='container'>
                  <div className='row' style={{marginTop: "150px"}}>
                    <div className='col-md-6 col-sm-12'>
                      <h1>
                        FREE 3-month trial
                      </h1>
                      <div className='header_description'>
                        <span>Contact us to receive a free trial! You will receive a cellular-connected BPM, with 3 months of free cellular data service and IoT Platform access. </span>
                      </div>
                      <div>
                        <Button onClick={scrollToContact} className='header-btn'>Try MioConnect for Free<div className='button-icon'><i className='iconfont icon-a-jiantou1'/></div></Button>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      {props.data &&
                        <img className='header-img-1' src={props.data.img1} style={{width: "100%"}}/>
                      }
                    </div>
                  </div>
              </div>
            </div>
          </div>
      </Carousel>
      }

      {Spinoff &&
        <div className='spinoff'>
          <Carousel dots={true} autoplay>
          <div className='intro'>
            <div className='overlay'>
                <div className='container'>
                  <div className='row' style={{marginTop: "150px"}}>
                    <div className='col-md-12'>
                        <h1>Connecting life and <br/> health with technology</h1>
                        <div className='header_description'>
                          <span>The two spinoff brand can not only provide good services in the field of communication, but also in the fields of medical equipment, household weighing equipment, and smart wearable devices. It has rich R&D and production background and can provide good technical support. In the future, more devices will be equipped with connected services to allow users to improve their health. Connecting life and health with technology, Miolabs will focus on developed and developing markets.</span>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          </Carousel>
        </div>
      }
    </header>
  )
}
